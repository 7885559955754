import React from 'react'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'

function Page404() {
  return (
    <div className="fourohfour-container">
      <h1>404 Page Not Found</h1>
      <p>we dont know how you ended up here, but here is some help heading home</p>
      <div className="home-icon-container">
        <Link to="/"><HomeIcon className="home-icon"/></Link>
      </div>
    </div>
  );
}

export default Page404;
