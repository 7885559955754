import axios from "axios";

import { API_URL, GOOGLE_MAP_KEY } from '../config'

class AdminService {

    reverseGeocode(latitude, longitude) {
        return axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&key=' + GOOGLE_MAP_KEY);
    }

    getCityById(cityId) {
        return axios.get(API_URL + "city/" + cityId);
    }

    getCityByName(name) {
        return axios.post(API_URL + "cities", { name } );
    }

    getZoneByName(name, cityId) {
        return axios
            .post(API_URL + "zones", { name, cityId });
    }

    getParcelInfo(zipcode, lotLongitude, lotLatitude) {
        return axios
            .post(API_URL + "parcels", { zipcode, lotLongitude, lotLatitude });
    }

    getZipcodeInfo(zipcode) {
        return axios.get(API_URL + "zipcode/" + zipcode)
    }

    getNeighborhoodInfo(lotLongitude, lotLatitude) {
        return axios
            .post(API_URL + "neighborhoods", { lotLongitude, lotLatitude });
    }

    //ar

    addARQuery(id, address, zipcode, latitude, longitude, cityId) {
        return axios
            .post(API_URL + "ar-query", { id, address, zipcode, latitude, longitude, cityId });
    }

    getAllARQueries() {
        return axios.get(API_URL + "ar-queries");
    }

    getARQueryById(id) {
        return axios.get(API_URL + "ar-query/" + id);
    }

    addARRequest(id, address, errortype) {
        return axios
            .post(API_URL + "arrequest", { id, address, errortype });
    }

    updateARRequest(id, email, dateContacted) {
        return axios
            .post(API_URL + "arrequest/" + id, { email, dateContacted });
    }

    getARRequests() {
        return axios.get(API_URL + "arrequests");
    }

}

export default new AdminService();