import React, { useState} from 'react'

import './project.css'

import Spreadsheet from "react-spreadsheet"

export default function Proforma({values}) {

    console.log(values);

    const {rentAverage, numberRooms} = values;

    const [data, setData] = useState([
        [{ value: "" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }, { value: "" }, { value: "" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Revenue" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "Net Operating Income" }],
        [{ value: "Apartments" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }, { value: "" }, { value: "Year on Year Growth" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: 0.1 }, { value: 0.1 }, { value: 0.1 }],
        [{ value: "Other Revenue" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }, { value: "" }, { value: "NOI" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Vacancy Loss" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "Operating Assumptions" }],
        [{ value: "Total Revenue" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }, { value: "" }, { value: "Number of Beds" }, { value: "" }, { value: numberRooms }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "Montly Revenue per Bed" }, { value: "" }, { value: rentAverage }, { value: (rentAverage + (rentAverage * 0.1)) }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Operating Expenses" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "% Vacancy Loss" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Regular Expenses" }],
        [{ value: "Real Estate Taxes" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Insurance" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Pass Through (Gas & Electric, Shared Goods, Cleaning)" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Fuel Expenses" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Utilities (Electric + Wifi)" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Water and Sewer" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Legal and Accounting" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "General and Administrative" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Bad Debt" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Broker's Expenses" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Super intendend/maintenance" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Variable Expenses" }],
        [{ value: "Other Common" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "E&F Reserves" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Contract Services" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Community" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Marketing/Brokerage" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Management Fee" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "" }],
        [{ value: "Total Expenses" }],
        [{ value: "As % of Net Revenue" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Rate of Growth RE Taxes" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Reate of groth for Expenses" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Management Fee as % of total" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "" }],
        [{ value: "Operating Assumptions" }],
        [{ value: "Number of Beds" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "Montly Revenue Per Bed" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
        [{ value: "% Vacancy Loss" }, { value: "" }, { value: "Year 0" }, { value: "Year 1" }, { value: "Year 2" }, { value: "Year 3" }, { value: "Year 4" }, { value: "Year 5" }],
      ]);

    console.log(data);

    return (
        <div className='proforma-wrapper'>
            <h2>Proforma</h2>
            <p>The numbers on this proforma are editable</p>
            <Spreadsheet data={data} onChange={setData} />
        </div>
    );
}