import React from 'react'

import 'react-slideshow-image/dist/styles.css'

import Search from '../components/search/search'
import useProgressiveImg from '../components/common/progressiveImage'


import VirtualTour from '../components/home/virtualTour'
import Maison from '../components/home/maison'
import PriorProjects from '../components/home/priorProjects'
import Design from '../components/home/design'
import Features from '../components/home/features'
//import Contact from '../components/home/contact'

import backgroundImage from '../files/images/splash.jpg';
import backgroundImageTiny from '../files/images/tinySplash.jpg';
import Uncertainty from '../components/home/uncertainty'
import Operations from '../components/home/operations'

import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function Home() {

  const [src, { blur }] = useProgressiveImg(backgroundImageTiny, backgroundImage);

  return (
    <div className="container">
      <div className="splash-container">
        <div className="splash-text-container" >
            <div className="splash-text-inner-wrapper">
              <h1>Increase your multifamily property’s NOI 25% or more</h1>
              <h2>Enter your address below to see what Maison can do for you</h2>
              <Search />
              <Button variant="outlined" href="#first">Learn More <KeyboardArrowDownIcon/></Button>
            </div>
        </div>
        <div className="splash-image" style={{
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}>
            <img src={src} alt="splash" />
        </div>
      </div>
      <div className="row centered two" id="first">
        <p>Maison is a one-stop-shop solution for building owners to increase revenue, while also delivering a beautiful housing product at competitive rents, to maximize the value of your building.</p>
      </div>
      <Operations />
      <Design />
      <Uncertainty />
      <VirtualTour />
      <div className="row centered two">
        <p>Achieve higher rent per square foot in our beautifully designed spaces.</p>
      </div>
      <Features />
      <Maison />
      <PriorProjects />
      <div className="row-last centered two">
        <p>Ready to see what Maison can do for you?</p>
        <Search />
        <p>Have questions?</p>
        <p>Give us a call at: <a href="tel:3474645556">+1 (347) 464-5556</a></p>
      </div>
    </div>
  );
}

export default Home;