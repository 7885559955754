import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import './project.css'

import LoadingSpinner from '../common/loadingSpinner'
import Utility from "./functions/utility"
import Sanatize from "./functions/sanatize"
import Calculations from "./functions/calculations"
import AdminService from '../../services/admin.service'
import Demographics from './demographics'

import { Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { GoogleMap, Polygon } from '@react-google-maps/api'

export default function ProjectReview() {

    let { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [queryInfo, setQueryInfo] = useState(null);
    const [parcelInfo, setParcelInfo] = useState(null);
    // eslint-disable-next-line
    const [zoneInfo, setZoneInfo] = useState(null);
    // eslint-disable-next-line
    const [neighborhoodInfo, setNeighborhoodInfo] = useState(null);
    // eslint-disable-next-line
    const [realtyMoleInfo, setRealtyMoleInfo] = useState(null);
    const [zipInfo, setZipInfo] = useState(null);
    const [calculatedValues, setCalculatedValues] = useState(null);

    const [parcelPolygon, setParcelPolygon] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '50VH'
    };

    const parcelLayerOptions = {
        fillColor: '#F19A3E',
        fillOpacity: 0.7,
        strokeColor: '#000',
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }


    useEffect(() => {
        (async function fetchAllData() {
            const { queryInfo, parcelInfo, zoneInfo, neighborhoodInfo, realtyMoleInfo } = await Utility.fetchInfo(id);
            setQueryInfo(queryInfo);
            setParcelInfo(parcelInfo);
            setZoneInfo(zoneInfo);
            setNeighborhoodInfo(neighborhoodInfo);
            setRealtyMoleInfo(realtyMoleInfo);
            setParcelPolygon(Sanatize.mapCoordinatesSanatize(parcelInfo.geo.coordinates));

            const zipInfo = await AdminService.getZipcodeInfo(queryInfo.zipcode).then(response => {return response.data});
            setZipInfo(zipInfo);

            const calculatedValues = Calculations.numbers(queryInfo, parcelInfo, zoneInfo, neighborhoodInfo, realtyMoleInfo);

            setCalculatedValues(calculatedValues);

            setLoading(false);
        })();
    }, [id]);

    if(loading === true) {
        return <div className="loading-spinner"><LoadingSpinner /></div>
    }

    else {
        return (
            <div>
                {queryInfo && (
                    <div className="project-wrapper">
                        <div className="project-basics">
                            <h1>{queryInfo.address}</h1>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <h2>Basics</h2>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Parcel Size</p>
                                                        <h2>{parseFloat(parcelInfo.size).toFixed(2)} sqft</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Building Size</p>
                                                        <h2>{parcelInfo.building_size} sqft</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Building Dimensions</p>
                                                        <h2>{parcelInfo.building_front}' x {parcelInfo.building_depth}'</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Number of Floors</p>
                                                        <h2>{parcelInfo.building_floors}</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <h2>Project Numbers</h2>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Estimated Units</p>
                                                        <h2>{calculatedValues.numberRooms}</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Projected Rent</p>
                                                        <h2>$ {calculatedValues.rentAverage.toLocaleString()}</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Estimated Revenue</p>
                                                        <h2>$ {calculatedValues.revenue.toLocaleString()}</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                                    <CardContent>
                                                        <p>Projected NOI</p>
                                                        <h2>$ {calculatedValues.noi.toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 })}</h2>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                                <Grid item xs={12} md={12}>
                                    <GoogleMap
                                        id='parcel-map'
                                        mapContainerStyle={containerStyle}
                                        center={{lat: parseFloat(parcelInfo.latitude), lng: parseFloat(parcelInfo.longitude)}}
                                        zoom={20}
                                        >
                                        <Polygon paths={parcelPolygon} options={parcelLayerOptions} />
                                    </GoogleMap>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <h2>Neighborhood Demographics</h2>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Demographics zipData={zipInfo} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}