import React, { Component } from 'react'

import './home.css'

import { Grid } from '@mui/material'

import backgroundImage from '../../files/images/bathroom1.jpg'

class Uncertainty extends Component {

    render() {

        return (
            <div className="row half">
                <Grid container spacing={4}>
                <Grid item xs={12} md={6} order={{ xs: 2 }}>
                    <img className="feature-image" src={backgroundImage} alt="test" />
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1 }}>
                    <h1 className="feature-title">Reduced Uncertainty</h1>
                    <p className="feature-body">
                        We’ve done this many times before and we’re here to take that experience to make your life easier. We have Maison properties all over NYC from Brooklyn, to Queens, and even Manhattan. We’ve worked with established developers to everyday property owners, taking their vacant or underdeveloped properties from desolate to desirable.
                    </p>
                </Grid>
                </Grid>
            </div>
        )
    }
}

export default Uncertainty