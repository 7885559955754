import React from 'react'

import image from '../../files/images/bookshelf.jpg';


import { Grid } from '@mui/material'

export default function Explainer() {

        return (
            <div className="row explainer">
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <h1>What's Next?</h1>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <h1>Step 1</h1>
                                <p>Grab a time on our calendar and we’ll connect. We’ll ask you a bit more about the layout of the current building to put the final touches on the floor plans and the detailed underwriting. This way, you will know the yield and ultimate ROI of a Maison project upfront. All of this at no cost to you!</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h1>Step 2</h1>
                                <p>If you like the plan, we’ll have our local Architect of Record confirm them and then send you the contract. We’ll file the plans with the department of buildings and we’ll be here to answer any questions they may have. If you already have a local general contractor you work with, great. If not, we will bring in some great local GC options.</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h1>Step 3</h1>
                                <p>Once the plans are approved by the DOB (~2-5 months) we will begin the renovation. We’ve done this over and over again, so we’ll be here every step of the way to make sure it goes smoothly and every element of the design creates the perfect Maison.</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h1>Step 4</h1>
                                <p>Near the end of completion we will furnish the property, take photos, and connect the building to our operators' sales channels who will handle the lease up. Once the property is filled (~3 months or less) our operator will handle all the day to day management and rent collection. We will oversee them and provide you with quarterly reports.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <img className="explainer-image" src={image} alt="explainer"/>
                    </Grid>
                </Grid>
            </div>
        );
    }