import axios from "axios";



class CompsService {

fetchRMData = async (longitude, latitude) => {
    var configMaisonette = {
        params: {
          compCount: '10',
          squareFootage: '360',
          bathrooms: '1',
          longitude: longitude,
          latitude: latitude,
          bedrooms: '1',
          propertyType: 'Apartment'
        },
        headers: {
          'x-rapidapi-key': '1a87c647f6msh64e833eacb5ac39p1a084djsnc8703ab6747b',
          'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com'
        }
    };

    var configMultifamily = {
      params: {
        compCount: '10',
        squareFootage: '900',
        bathrooms: '1',
        longitude: longitude,
        latitude: latitude,
        bedrooms: '2',
        propertyType: 'Apartment'
      },
      headers: {
        'x-rapidapi-key': '1a87c647f6msh64e833eacb5ac39p1a084djsnc8703ab6747b',
        'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com'
      }
  };

    const maisonette = await axios.get('https://realty-mole-property-api.p.rapidapi.com/rentalPrice', configMaisonette);
    const multifamily = await axios.get('https://realty-mole-property-api.p.rapidapi.com/rentalPrice', configMultifamily);

    const result = { maisonette: maisonette.data, multifamily: multifamily.data };

    return result;
}

}

export default new CompsService();