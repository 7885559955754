import React, { Component } from 'react'

import './home.css'

class Feature extends Component {

    render() {

        return (
            <div className="feature" style={{ backgroundColor: this.props.backgroundColor }}>
                <h1 className="circle-feature-title">{this.props.title}</h1>
                <p className="circle-feature-body">{this.props.body}</p>
            </div>
        )
    }
}

export default Feature