import React, { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

class LoadingSpinner extends Component {

    render() {

        return (
            <CircularProgress color="secondary" size="4em" />
        )
    }
}

export default LoadingSpinner