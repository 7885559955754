import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

class SingleSearch extends Component {
  render() {
    const {
      search: {
        id,
        address,
        createdAt 
      }
    } = this.props;

    return (
        <TableBody>
          <TableRow key={id}>
            <TableCell><Link to={`project/${id}/admin`}>{address}</Link></TableCell>
            <TableCell><Moment date={createdAt} format="MMMM DD, YYYY" /></TableCell>
          </TableRow>
        </TableBody>
    );
  }
}

export default SingleSearch