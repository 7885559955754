import React, { Component } from 'react'

import './home.css'

import { Grid } from '@mui/material'

import backgroundImage from '../../files/images/maison1.jpg'

class Design extends Component {

    render() {

        return (
            <div className="row half">
                <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <h1 className="feature-title">World Class Design</h1>
                    <p className="feature-body">
                        Maison is made for everyone and anyone seeking to embrace the magic of the cities we live in. Maison erases the difficulties of moving in, enabling tenants to feel home as soon as they step in the door. Through great design, tenants feel happier and stay longer.
                    </p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img className="feature-image" src={backgroundImage} alt="test" />
                </Grid>
                </Grid>
            </div>
        )
    }
}

export default Design