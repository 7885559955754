import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Helmet from "react-helmet";

import Iframe from "react-iframe";

import "./project.css";

import LoadingSpinner from "../common/loadingSpinner";
import Cta from "../common/cta";
import Utility from "./functions/utility";
import Sanatize from "./functions/sanatize";
import Calculations from "./functions/calculations";
import AdminService from "../../services/admin.service";
import Demographics from "./demographics";
import Explainer from "./explainer";
import Email from "./email";

import {
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { GoogleMap, Polygon } from "@react-google-maps/api";

export default function Project() {
  let { id } = useParams();

  var width = window.innerWidth;

  const [loading, setLoading] = useState(true);
  const [queryInfo, setQueryInfo] = useState(null);
  const [parcelInfo, setParcelInfo] = useState(null);
  // eslint-disable-next-line
  const [zoneInfo, setZoneInfo] = useState(null);
  // eslint-disable-next-line
  const [neighborhoodInfo, setNeighborhoodInfo] = useState(null);
  // eslint-disable-next-line
  const [realtyMoleInfo, setRealtyMoleInfo] = useState(null);
  const [zipInfo, setZipInfo] = useState(null);
  const [calculatedValues, setCalculatedValues] = useState(null);

  const [parcelPolygon, setParcelPolygon] = useState(null);

  const [unitMix] = useState(0);

  var containerStyle = {
    width: "100%",
    height: "100%",
    minHeight: "825px",
  };

  if (width <= 600) {
    containerStyle = {
      width: "100%",
      height: "400px",
    };
  }

  const parcelLayerOptions = {
    fillOpacity: 0,
    strokeColor: "#F19A3E",
    strokeWeight: 6,
    clickable: false,
    draggable: false,
    editable: false,
  };

  useEffect(() => {
    (async function fetchAllData() {
      const {
        queryInfo,
        parcelInfo,
        zoneInfo,
        neighborhoodInfo,
        realtyMoleInfo,
      } = await Utility.fetchInfo(id);
      setQueryInfo(queryInfo);
      setParcelInfo(parcelInfo);
      setZoneInfo(zoneInfo);
      setNeighborhoodInfo(neighborhoodInfo);
      setRealtyMoleInfo(realtyMoleInfo);
      setParcelPolygon(
        Sanatize.mapCoordinatesSanatize(parcelInfo.geo.coordinates)
      );

      const zipInfo = await AdminService.getZipcodeInfo(queryInfo.zipcode).then(
        (response) => {
          return response.data;
        }
      );
      setZipInfo(zipInfo);

      const calculatedValues = Calculations.numbers(
        queryInfo,
        parcelInfo,
        zoneInfo,
        neighborhoodInfo,
        realtyMoleInfo,
        unitMix
      );

      setCalculatedValues(calculatedValues);

      setLoading(false);
    })();
  }, [id, unitMix]);

  /*

    const handleUnitMixChange = (event, value) => {
        setUnitMix(value);
    }
    

    const marks = [
        {
          value: 0,
          label: '100% Micro-studios',
        },
        {
            value: 25,
            label: '75/25 Sudios/Co-living',
        },
        {
          value: 50,
          label: '50/50 Sudios/Co-living',
        },
        {
            value: 75,
            label: '25/75 Sudios/Co-living',
        },
        {
          value: 100,
          label: '100% Co-living',
        },
    ];
    */

  if (loading === true) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="loading-spinner">
          <LoadingSpinner />
        </div>
      </>
    );
  } else if (width <= 900) {
    return (
      <>
        <Helmet>
          <title>{queryInfo.address} | Maison NOI Optimizer</title>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div>
          {queryInfo && (
            <div className="project-wrapper">
              <div className="project-basics">
                <h1>{queryInfo.address}</h1>
                <a href="#project-info">
                  <Button color="primary" variant="contained">
                    Optimize My Property
                  </Button>
                </a>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <h2>Building Information</h2>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Parcel Size</p>
                                <h2>
                                  {parseFloat(parcelInfo.size).toFixed(2)} sqft
                                </h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Building Size</p>
                                <h2>{parcelInfo.building_size} sqft</h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Building Dimensions</p>
                                <h2>
                                  {parcelInfo.building_front}' x{" "}
                                  {parcelInfo.building_depth}'
                                </h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Number of Floors</p>
                                <h2>{parcelInfo.building_floors}</h2>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <GoogleMap
                          id="parcel-map"
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: parseFloat(parcelInfo.latitude),
                            lng: parseFloat(parcelInfo.longitude),
                          }}
                          zoom={18}
                          options={{
                            draggable: true, // make map draggable
                            zoomControlOptions: { position: 9 },
                            keyboardShortcuts: false, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle,
                            mapTypeId: "satellite",
                          }}
                        >
                          <Polygon
                            paths={parcelPolygon}
                            options={parcelLayerOptions}
                          />
                        </GoogleMap>
                      </Grid>
                      <Grid item xs={12} md={12} id="project-info">
                        <h1>
                          Take a look at the information below to see how Maison
                          would optimize your building.
                        </h1>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h2>Projected Numbers And Analysis</h2>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Estimated Total Keys</p>
                                <h2>{calculatedValues.numberRooms}</h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Projected Rent/Key</p>
                                <h2>
                                  ${" "}
                                  {calculatedValues.rentAverage.toLocaleString()}
                                </h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card style={{ backgroundColor: "#f2f2f2" }}>
                              <CardContent>
                                <p>Estimated Revenue</p>
                                <h2>
                                  $ {calculatedValues.revenue.toLocaleString()}
                                </h2>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Card
                              style={{
                                backgroundColor: "#AF1203",
                                color: "#fff",
                              }}
                            >
                              <CardContent>
                                <p>Projected NOI With Maison</p>
                                <h2>
                                  ${" "}
                                  {calculatedValues.noi.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </h2>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/**
                            <Grid item x={12} md={6}>
                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <p>Current Unit Mix</p>
                                                <h2>{calculatedValues.numberRooms}<br/>{calculatedValues.roomType}</h2>
                                            </Grid>
                                            
                                             <Grid item xs={12} md={6}>
                                                <div className="unit-mix-slider">
                                                    <Slider
                                                        orientation="vertical"
                                                        color="secondary"
                                                        defaultValue={unitMix}
                                                        step={null}
                                                        marks={marks}
                                                        onChange={handleUnitMixChange}
                                                        track={false}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Email
                      address={queryInfo.address}
                      id={id}
                      calculatedValues={calculatedValues}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h2>Neighborhood Demographics</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Demographics zipData={zipInfo} />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="project-row">
                      <h1>3D Walkthrough</h1>
                      <Iframe
                        url="https://renderings.madelongroup.com/madelon-jefferson-2/"
                        width="100%"
                        height="600px"
                        id="threedvisualinterior"
                        frameBoarder="0"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Explainer />
                <div>
                  <h2>
                    Ready to see what Maison can do for you? Click the link
                    below and let’s talk!
                  </h2>
                  <Cta id={id} address={queryInfo.address} />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{queryInfo.address} | Maison NOI Optimizer</title>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div>
          {queryInfo && (
            <div className="project-wrapper">
              <div className="project-basics">
                <div className="project-info-wrapper">
                  <div className="project-left">
                    <div className="basic-info">
                      <h1>{queryInfo.address}</h1>
                      <a href="#project-info">
                        <Button color="primary" variant="contained">
                          Optimize My Property
                        </Button>
                      </a>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={12}>
                          <h2>Basics Building Info</h2>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                              <Card style={{ backgroundColor: "#f2f2f2" }}>
                                <CardContent>
                                  <p>Parcel Size</p>
                                  <h2>
                                    {parseFloat(parcelInfo.size).toFixed(2)}{" "}
                                    sqft
                                  </h2>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Card style={{ backgroundColor: "#f2f2f2" }}>
                                <CardContent>
                                  <p>Building Size</p>
                                  <h2>{parcelInfo.building_size} sqft</h2>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Card style={{ backgroundColor: "#f2f2f2" }}>
                                <CardContent>
                                  <p>Building Dimensions</p>
                                  <h2>
                                    {parcelInfo.building_front}' x{" "}
                                    {parcelInfo.building_depth}'
                                  </h2>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Card style={{ backgroundColor: "#f2f2f2" }}>
                                <CardContent>
                                  <p>Number of Floors</p>
                                  <h2>{parcelInfo.building_floors}</h2>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  {/**
                            <Grid item x={12} md={6}>
                                <Card style={{backgroundColor: "#f2f2f2"}}>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <p>Current Unit Mix</p>
                                                <h2>{calculatedValues.numberRooms}<br/>{calculatedValues.roomType}</h2>
                                            </Grid>
                                            
                                             <Grid item xs={12} md={6}>
                                                <div className="unit-mix-slider">
                                                    <Slider
                                                        orientation="vertical"
                                                        color="secondary"
                                                        defaultValue={unitMix}
                                                        step={null}
                                                        marks={marks}
                                                        onChange={handleUnitMixChange}
                                                        track={false}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            */}
                  <div className="map-div">
                    <GoogleMap
                      id="parcel-map"
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: parseFloat(parcelInfo.latitude),
                        lng: parseFloat(parcelInfo.longitude),
                      }}
                      zoom={19}
                      tilt={45}
                      options={{
                        draggable: true, // make map draggable
                        zoomControlOptions: { position: 9 },
                        keyboardShortcuts: false, // disable keyboard shortcuts
                        scaleControl: true, // allow scale controle,
                        mapTypeId: "satellite",
                      }}
                    >
                      <Polygon
                        paths={parcelPolygon}
                        options={parcelLayerOptions}
                      />
                    </GoogleMap>
                  </div>
                </div>
                <div className="project-row centered" id="project-info">
                  <h1>
                    Take a look at the information below to see how Maison would
                    optimize your building.
                  </h1>
                </div>
                <div className="project-row">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <h2>Projected Numbers And Analysis</h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card style={{ backgroundColor: "#f2f2f2" }}>
                            <CardContent>
                              <p>Estimated Total Keys</p>
                              <h2>{calculatedValues.numberRooms}</h2>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card style={{ backgroundColor: "#f2f2f2" }}>
                            <CardContent>
                              <p>Projected Rent/Key</p>
                              <h2>
                                ${" "}
                                {calculatedValues.rentAverage.toLocaleString()}
                              </h2>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card style={{ backgroundColor: "#f2f2f2" }}>
                            <CardContent>
                              <p>Estimated Revenue</p>
                              <h2>
                                $ {calculatedValues.revenue.toLocaleString()}
                              </h2>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Card
                            style={{
                              backgroundColor: "#AF1203",
                              color: "#fff",
                            }}
                          >
                            <CardContent>
                              <h1>Projected NOI With Maison</h1>
                              <h1>
                                ${" "}
                                {calculatedValues.noi.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </h1>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Email
                        address={queryInfo.address}
                        id={id}
                        calculatedValues={calculatedValues}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h2>Neighborhood Demographics</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Demographics zipData={zipInfo} />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <h1>3D Walkthrough</h1>
                      <Iframe
                        url="https://renderings.madelongroup.com/madelon-jefferson-2/"
                        width="100%"
                        height="600px"
                        id="threedvisualinterior"
                        frameBoarder="0"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Explainer />
                    </Grid>
                  </Grid>
                </div>
                <div className="project-cta-section">
                  <h2>
                    Ready to see what Maison can do for you? Click the link
                    below and let’s talk!
                  </h2>
                  <Cta id={id} address={queryInfo.address} />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
