import React, { Component } from 'react'
import Helmet from 'react-helmet'

import AllFAQs from '../components/faqs/faqs'

class FAQs extends Component {

    render() {

        return (
            <>
            <Helmet>
                <title>FAQs | Maison NOI Optimizer</title>
            </Helmet>
            <div className="basic-container">
                <h1>Frequently Asked Questions</h1>
                <AllFAQs />
            </div>
            </>
        )
    }
}

export default FAQs