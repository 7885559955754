import React, { Component } from 'react'

import './home.css'

class PriorProject extends Component {

    render() {

        return (
            <div className="each-slide">
                <div style={{'backgroundImage': `url(${this.props.projectImage})`}}>
                    <div className="project-inner-wrapper">
                        <h2 className="project-title">{this.props.projectTitle}</h2>
                        <div className="project-quick-facts">
                            Location: <span>{this.props.projectLocation}</span> <br/>
                            Size: <span>{this.props.buildingSize} sqft</span> <br/>
                            Keys: <span>{this.props.rooms}</span> <br/>
                            NOI Increase: <span>{this.props.noi}</span>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default PriorProject