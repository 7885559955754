import React, { Component } from 'react'
import Helmet from 'react-helmet'

import TermsAndConditionsText from '../components/common/tandc'

class TermsAndConditions extends Component {

    render() {

        return (
            <>
            <Helmet>
                <title>Terms and Conditions | Maison NOI Optimizer</title>
            </Helmet>
            <div className="basic-container">
                <TermsAndConditionsText />
            </div>
            </>
        )
    }
}

export default TermsAndConditions