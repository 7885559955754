import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import './header.css'

import makeStyles from '@mui/styles/makeStyles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import maisonLogo from '../../files/images/maisonLogo.svg'

const useStyles = makeStyles((theme) => ({
  appBarTransparent: {
      backgroundColor: 'rgba(255, 255, 255,0)'
  },
  appBarSolid: {
      backgroundColor: 'rgba(255, 255, 255)'
  }
}));


export default function Header() {

  const classes = useStyles();

  const [navBackground] = useState('appBarSolid');
  const navRef = useRef();
  navRef.current = navBackground;

  return (
    <div className='header'>
      <AppBar elevation={0} position="fixed" className={classes[navRef.current]}>
        <Toolbar>
          <div className="toolbar-wrapper">
            <div className="nav-logo-container">
              <Link to="/">
                  <img className="nav-logo" src={maisonLogo} alt="Masion Logo" />
              </Link>
              <h3 className="nav-subtext">NOI Optimizer</h3>
            </div>
            <div className="nav-number">
              <p>Contact Us: <a href="tel:3474645556">+1 (347) 464-5556</a></p>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}