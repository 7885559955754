import React, { Component } from 'react'

import { Slide } from 'react-slideshow-image'

import PriorProject from './priorProject'

import './home.css'


import project1 from '../../files/images/maisonette1.jpg'
import project2 from '../../files/images/property1.jpg'
import project3 from '../../files/images/property2.png'
import project4 from '../../files/images/property3.png'


class PriorProjects extends Component {

    render() {

        return (
            <div className="row">
                <h1>Current Maison Properties</h1>
                <Slide easing="ease">
                    <PriorProject projectTitle="Maison Jefferson" projectLocation="Bed-Stuy, NYC" projectImage={project1} buildingSize="6,834" rooms="27" noi="34%"/>
                    <PriorProject projectTitle="Maison Edgecombe" projectLocation="Upper Manhattan, NYC" projectImage={project2} buildingSize="11,230" rooms="35" noi="24%" />
                    <PriorProject projectTitle="Maison Suydam" projectLocation="Bushwick, NYC" projectImage={project3} buildingSize="6,423" rooms="18" noi="27%" />
                    <PriorProject projectTitle="Maison Linden" projectLocation="Ridgewood, NYC" projectImage={project4} buildingSize="6,739" rooms="18" noi="29%" />
                </Slide>
            </div>
        )
    }
}

export default PriorProjects