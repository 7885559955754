import React, { Component } from 'react'

import './home.css'

import { Grid } from '@mui/material'

import backgroundImage from '../../files/images/maisonette2.jpg'

class Operations extends Component {

    render() {

        return (
            <div className="row half">
                <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <h1 className="feature-title">A Simple Proven Process</h1>
                    <p className="feature-body">
                    Leverage our productized and proven process to deliver a final product at a low cost. We have an amazing network of architects, contractors, and operators who we’ve pre-vetted for our previous and current projects, so we are able to recreate our success at new properties over and over again at a highly competitive cost.
                    </p>
                    <p className="feature-body">
                        Don’t recreate the wheel on your own, let us take you from building to cash flow.
                    </p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img className="feature-image" src={backgroundImage} alt="test" />
                </Grid>
                </Grid>
            </div>
        )
    }
}

export default Operations