import React from 'react'

import Helmet from 'react-helmet'

import AllSearches from '../components/admin/allSearches'
import AllRequests from '../components/admin/allRequests'

import { Grid } from '@mui/material'


function Dashboard() {
  return (
    <>
    <Helmet>
        <title>Dashboard | Maison NOI Optimizer</title>
        <meta name="robots" content="noindex"></meta>
    </Helmet>
    <div className="basic-container">
      <h1>Dashboard</h1>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <AllSearches />
        </Grid>
        <Grid item xs={12} md={7}>
          <AllRequests />
        </Grid>
      </Grid>
    </div>
    </>
  );
}

export default Dashboard;