import React, { Component } from "react";

import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import AdminService from "../../services/admin.service";

import SingleRequest from './singleRequest'

class AllRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: []
    };
  }

  componentDidMount() {
    AdminService.getARRequests()
    .then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {

    const allRequests = this.state.content;

    const recentRequests = allRequests.map((request) => <SingleRequest key={request.id} request={request} />);
    const sortedRequests = recentRequests.sort((a,b) => {return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()}).reverse();

    return (
      <>
        <h2>Recent Unsuccessful Searches</h2>
        Total: {allRequests.length}
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow key={"index"}>
                        <TableCell style={{fontSize: "1.1em"}}>Address</TableCell>
                        <TableCell style={{fontSize: "1.1em"}}>Email</TableCell>
                        <TableCell style={{fontSize: "1.1em"}}>Date Added</TableCell>
                        <TableCell style={{fontSize: "1.1em"}}>Error Type</TableCell>
                        <TableCell style={{fontSize: "1.1em"}}>Date Contacted</TableCell>
                    </TableRow>
                </TableHead>
                {sortedRequests}
            </Table>
        </TableContainer>
        </>
    );
  }
}

export default AllRequests
