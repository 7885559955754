import React, { Component } from 'react'

import './home.css'

import MaisonLogo from '../../files/images/maisonLogo.svg'

import { Button } from '@mui/material'

class Maison extends Component {

    render() {

        return (
            <div className="row">
                <div className="brand-row centered">
                    <img className="brand-logo" src={MaisonLogo} alt="maison logo" />
                </div>
                <div className="brand-row centered">
                    <p>
                        Our housing brand Maison increases NOI for building owners and offers more accessible living arrangements for tenants.
                    </p>
                    <a href="https://maisonliving.io" target="_blank" rel="noreferrer"><Button color='secondary' variant='outlined' size="large">Discover More</Button></a>
                </div>
            </div>
        )
    }
}

export default Maison