import React, { Component } from 'react'
import Helmet from 'react-helmet'

import PrivacyPolicyText from '../components/common/pp'

class PrivacyPolicy extends Component {

    render() {

        return (
            <>
            <Helmet>
                <title>Privacy Policy | Maison NOI Optimizer</title>
            </Helmet>
            <div className="basic-container">
                <PrivacyPolicyText />
            </div>
            </>
        )
    }
}

export default PrivacyPolicy