import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AdminService from '../../services/admin.service';

import axios from "axios";
import { API_URL } from '../../config'

import { v4 as uuidv4 } from 'uuid'

import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';


import './search.css'

import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";

import { GOOGLE_MAP_KEY } from '../../config'
import LoadingSpinner from '../common/loadingSpinner';

const styles = {
    root: {
      background: "#fff",
      flexGrow: 1,
    },
    input: {
      color: "#000"
    }
};

function Search(props) {

    const { classes } = props;
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [googlePlace, setGooglePlace] = useState(null);
    const [dialogContent, setDialogContent] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [arDialogOpen, setARDialogOpen] = useState(false);
    const [responseDialogOpen, setResponseDialogOpen] = useState(false);

    const { ref: materialRef } = usePlacesWidget({
        apiKey: GOOGLE_MAP_KEY,
        onPlaceSelected: (place) => setGooglePlace(place),
        inputAutocompleteValue: "address",
        options: {
          types: "address"
        }
    });

    const handleSearch = async () => {

        window.lintrk('track', { conversion_id: 5654706 });

        var name = googlePlace.address_components[3].long_name;

        if (name === 'New York' || name === 'Brooklyn' || name === 'Queens' || name === 'Bronx' || name === 'Manhattan') {
            name = 'New York';

            const parcelInfo = await AdminService.getParcelInfo(googlePlace.address_components[7].short_name.toString(), googlePlace.geometry.location.lng().toString(), googlePlace.geometry.location.lat().toString()).then(response => {return response.data});

            if (parcelInfo.building_size !== 0) {

                setLoading(true);

                const queryId = uuidv4();

                const city = await AdminService.getCityByName(name);
                await AdminService.addARQuery(queryId, googlePlace.formatted_address, googlePlace.address_components[7].short_name, googlePlace.geometry.location.lat(), googlePlace.geometry.location.lng(), city.data.id);

                history.push(`project/${queryId}`);
            }
            else {
                const requestId = uuidv4();
                await AdminService.addARRequest(requestId, googlePlace.formatted_address.toString(), 'existing-building');
                setRequestId(requestId);
                setARDialogOpen(true);
            }
        }

        else {
            const requestId = uuidv4();
            await AdminService.addARRequest(requestId, googlePlace.formatted_address.toString(), 'outside-target');
            setRequestId(requestId);
            setDialogOpen(true);
        }


    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleEmail = async () => {
        await AdminService.updateARRequest(requestId, email);
        const from = "maison@madelongroup.com";
        const subject = `${googlePlace.formatted_address}`;
        const message = `<p>Hi there,</p>
        <p>We're happy to hear you're interested in increasing your NOI with Maison at ${googlePlace.formatted_address}.</p>
        <p>Unfortunately the optimizer was unable to generate automated estimates for the numbers on this project.  Our team will take a look at the address and connect back with you about potential options after we run the address through our manual process.</p>
        <p>
        Team Maison <br/>
        A Madelon Company
        </p>`;
        axios.post(API_URL + "send-email", { from, email, subject, message })
        .then(response => {
            setDialogContent("Success! You should expect to recieve an email shortly!");
         })
         .catch(error => {
            setDialogContent("Error! Please enter a valid email address.");
         });
        setResponseDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false);
        setARDialogOpen(false);
        setResponseDialogOpen(false);
    };
    
      return (
            <div>
                <div className="search-container">
                    <TextField
                        color="primary"
                        required
                        variant="outlined"
                        className={classes.root}
                        inputRef={materialRef}
                        InputLabelProps={{ required: true }}
                        InputProps={{
                            className: classes.input
                        }}
                        placeholder='Enter an address'
                    />
                    <Button variant="contained" size="large" color="primary" className="search-button" onClick={handleSearch}>
                        Check Address
                    </Button>
                </div>
                <div className="loading-spinner">
                    { loading === true && (
                        <LoadingSpinner />
                    )}
                </div>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
                    <DialogTitle id="simple-dialog-title">Apologies our automated platform currenly only works within New York City.</DialogTitle>
                    <DialogContent>
                        <DialogContent>
                            <p>Enter you email address if you would like us to run this address through our manual process and send you an information packet!</p>
                            <TextField
                                fullwidth
                                color="primary"
                                required
                                variant="outlined"
                                placeholder='Email Address'
                                onChange={onChangeEmail}
                            />
                            <p>If you think this is a mistake or have other questions give us a call:  <a href="tel:3474645556">+1 (347) 464-5556</a> or send us an email:  <a href="mailto:maison@madelongroup.com">maison@madelongroup.com</a></p>
                        </DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="large" color="primary" className="search-button" onClick={handleEmail}>
                            Submit
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={arDialogOpen}>
                    <DialogTitle id="simple-dialog-title">Apologies we believe this property to be vacant land therefore</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p>Please try our Madelon REDtech platform for ground up development here: <a href="https://redtech.app" target="_blank" rel="noreferrer">https://redtech.app</a></p>
                            <p>If you think this is an error please give us a call:  <a href="tel:3474645556">+1 (347) 464-5556</a> or send us an email:  <a href="mailto:maison@madelongroup.com">maison@madelongroup.com</a></p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={responseDialogOpen}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
      );
    }

Search.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Search);