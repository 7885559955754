import React, { Component } from 'react'

import './faq.css'

import FAQ from './faq'

class FAQs extends Component {

    render() {

        return (
            <div className="faq-wrapper"> 
                <div className="left">
                    <FAQ id="1" title="What cities does Maison currently work in?" body="Currently the Maison NOI Optimizer works automatically in the five boroughs of New York City.  However, we are happy to discuss potential projects around the country with a focus on NYC, Denver, and Los Angeles." />
                    <FAQ id="2" title="What types of projects does Maison work with?" body="We work with all types of multi-family properties, and can even convert hotels and some office buildings to a Maison (depending on the existing floorplate). The bigger the better. " />
                </div>
                <div className="right">
                    <FAQ id="3" title="What is the minimum project size?" body="Generally we would need the project to be over ~6,000 square feet to maximize efficiency. This often means a minimum of 3 units per building, with no upper limit. " />
                    <FAQ id="4" title="Does Maison become an owner?" body="Nope. We just charge for the conversion work and a small ongoing fee for licensing the Maison brand. All of these fees are already included in our NOI projections. " />
                </div>
            </div>
        )
    }
}

export default FAQs