import React, { Component } from 'react'

import './common.css'

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import { InlineWidget } from "react-calendly"

class Cta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false
        };
    }

    openDialog = () => {
        this.setState({ dialogOpen: true });
    }

    handleClose = () => {
        this.setState({ dialogOpen: false });
    }

    render() {

        return (
            <div>
                <Button color='primary' variant='contained' onClick={this.openDialog}>Chat about this property?</Button>
                <Dialog fullWidth={true} maxWidth='md' onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.dialogOpen}>
                    <DialogTitle id="simple-dialog-title">We're looking forward to chatting with you! Select a time below that works for you and Dane will give you a call.</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <InlineWidget url={`https://calendly.com/dane-madelon/15min`} prefill={{ customAnswers: { a1: `Project ID: ${this.props.id} Address: ${this.props.address}` }}} />
                            <p className="cta">Alternativley shoot us an email <a href="mailto:maison@madelongroup.com">maison@madelongroup.com</a> or give us a call <a href="tel:3474645556">+1 (347) 464-5556</a></p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default Cta