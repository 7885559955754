import React, {useState} from 'react'

import { TextField, Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material'

import axios from "axios";
import { API_URL } from '../../config'

export default function Email(props) {

        const [email, setEmail] = useState(null);
        const [dialogOpen, setDialogOpen] = useState(false);
        const [dialogContent, setDialogContent] = useState(null);

        const onChangeEmail = (e) => {
            setEmail(e.target.value);
        }

        const handleEmail = () => {
            const from = "maison@madelongroup.com";
            const subject = `${props.address} - Maison Adaptive Reuse Project`;
            const message = `<p>Hi there,</p>
            <p>We're happy to hear you're interested in increasing your NOI with Maison at ${props.address}.</p>
            <p>In an early analysis, we see the projected numbers for this building are:
            <ul>
            <li>Number of Keys: ${props.calculatedValues.numberRooms}</li>
            <li>Rent Per Key: ${props.calculatedValues.rentAverage.toLocaleString()}</li>
            <li>Projected Revenue: $${props.calculatedValues.revenue.toLocaleString()}</li>
            <li>Projected NOI: $${props.calculatedValues.noi.toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 })}</li>
            </ul>
            </p>
            <p>Feel free to take a look at the packet below for some additional information about our process!</p>
            <p>Packet link https://optimizemynoi.com/project/${props.id}/packet</p>
            <p>
            And then set up a time to connect to learn more about our financial projections and how to get started! <br/>
            <a href="https://calendly.com/dane-madelon/15min">Select Time</a>
            </p>
            <p>
            Team Maison <br/>
            A Madelon Company
            </p>`;
            axios.post(API_URL + "send-email", { from, email, subject, message })
            .then(response => {
                setDialogContent("Success! You should expect to recieve an email shortly!");
             })
             .catch(error => {
                setDialogContent("Error! Please enter a valid email address.");
             });
            setDialogOpen(true);
        }

        const handleClose = () => {
            setDialogOpen(false);
        };

        return (
            <div className="row">
            <h1>If you would like more details on the Maison product and how it would work at your building, enter your email below and we will shoot over our information packet.</h1>
            <div className="email-container">
                <TextField
                    color="primary"
                    required
                    variant="outlined"
                    placeholder='Email Address'
                    onChange={onChangeEmail}
                    className="email-field"
                />
                <Button variant="contained" size="large" color="primary" className="search-button" onClick={handleEmail}>
                    Send Me More Info
                </Button>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialogOpen}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }