import React, { Component } from 'react'

import { Line } from 'react-chartjs-2'

import Grid from '@mui/material/Grid'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

class Demographics extends Component {
    
    render() {

        const { zipData } = this.props;

        var popData = {};

        if (zipData) {

            const pop2010 = zipData.population2010;
            const pop2011 = zipData.population2011;
            const pop2012 = zipData.population2012;
            const pop2013 = zipData.population2013;
            const pop2014 = zipData.population2014;
            const pop2015 = zipData.population2015;
            const pop2016 = zipData.population2016;
            const pop2020 = zipData.population2020;

            popData = {labels: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2020'], datasets: [
                {
                  label: 'Population',
                  data: [pop2010, pop2011, pop2012, pop2013, pop2014, pop2015, pop2016, pop2020],
                  fill: false,
                  backgroundColor: 'rgb(241, 154, 62)',
                  borderColor: 'rgba(241, 154, 62, 0.2)',
                },
              ],};
        }

        const options = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: false,
                  },
                },
              ],
            },
          };

        return (
            <>
                {zipData && (
<>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Population</h3>
                                            <Line data={popData} options={options} />
                                            <Typography color="textSecondary" gutterBottom>
                                            Population Density: {parseFloat(zipData.density).toLocaleString()} / km<sup>2</sup>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Age</h3>
                                            <Typography color="textSecondary" gutterBottom>
                                                Median Age: {zipData.age_median}
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Percentage 20s: {zipData.age_20s} %
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Percentage 30s: {zipData.age_30s} %
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Family/Income</h3>
                                            <Typography color="textSecondary" gutterBottom>
                                                Family Size: {zipData.family_size}
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Median Household Income: ${parseFloat(zipData.income_household_median).toLocaleString()}
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Median Individual Income: ${parseFloat(zipData.income_individual_median).toLocaleString()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Real Estate</h3>
                                            <Typography color="textSecondary" gutterBottom>
                                                Percentage Home Ownership: {zipData.home_ownership} %
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Home Value: ${parseFloat(zipData.home_value).toLocaleString()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Employment/Poverty</h3>
                                            <Typography color="textSecondary" gutterBottom>
                                                Unemployment Rate: {zipData.unemployment_rate} %
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Poverty: {zipData.poverty} %
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <h3>Commute</h3>
                                            <Typography color="textSecondary" gutterBottom>
                                                Average Commute Time: {zipData.commute_time} min
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
</>
                )}
            </>
        )
    }
}

export default Demographics
