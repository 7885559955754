import AdminService from "../../../services/admin.service";
import CompsService from "../../../services/comps.service";

class Utility {
  fetchInfo = async (queryId) => {
    const queryInfo = await AdminService.getARQueryById(queryId).then(
      (response) => {
        return response.data;
      }
    );

    const parcelInfo = await AdminService.getParcelInfo(
      queryInfo.zipcode.toString(),
      queryInfo.longitude.toString(),
      queryInfo.latitude.toString()
    ).then((response) => {
      return response.data;
    });

    const zoneInfo = await AdminService.getZoneByName(
      parcelInfo.zone_name,
      parcelInfo.cityId
    ).then((response) => {
      return response.data;
    });

    const neighborhoodInfo = await AdminService.getNeighborhoodInfo(
      queryInfo.longitude.toString(),
      queryInfo.latitude.toString()
    ).then((response) => {
      return response.data;
    });

    var realtyMoleInfo = {
      maisonette: {
        rent: parcelInfo.compsRent,
        rentRangeHigh: parcelInfo.compsRentHigh,
        rentRangeLow: parcelInfo.compsRentLow,
      },
      multifamily: {
        rent: parcelInfo.compsRentMulti,
        rentRangeHigh: parcelInfo.compsRentHighMulti,
        rentRangeLow: parcelInfo.compsRentLowMulti,
      },
    };

    if (
      !parcelInfo.compsDate ||
      parcelInfo.compsRent === null ||
      parcelInfo.compsRentMulti === null
    ) {
      realtyMoleInfo = await CompsService.fetchRMData(
        queryInfo.longitude.toString(),
        queryInfo.latitude.toString()
      );
    }

    return {
      queryInfo,
      parcelInfo,
      zoneInfo,
      neighborhoodInfo,
      realtyMoleInfo,
    };
  };
}

export default new Utility();
