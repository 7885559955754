import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import DownloadIcon from '@mui/icons-material/Download'
import { IconButton } from '@mui/material'

import infoPacket from '../../files/infoPacket.pdf'

export default function MyApp() {
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePrev = () => {
    if (pageNumber !== 1) {
        setPageNumber(pageNumber - 1);
    }
  }
  const handleNext = () => {
    if (pageNumber !== numPages) {
        setPageNumber(pageNumber + 1);
    }
  }

  return (
    <div className="packet">
      <Document
        file={infoPacket}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <IconButton onClick={handlePrev}>
          <KeyboardArrowLeftIcon />
      </IconButton>
      {pageNumber} of {numPages}
      <IconButton onClick={handleNext}>
          <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton href={infoPacket} download>
        <DownloadIcon />
      </IconButton>
    </div>
  );
}