import React, { Component } from "react";

import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import AdminService from "../../services/admin.service";

import SingleSearch from './singleSearch'

class AllSearches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: []
    };
  }

  componentDidMount() {
    AdminService.getAllARQueries()
    .then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {

    const allSearches = this.state.content;

    const recentSearches = allSearches.map((search) => <SingleSearch key={search.id} search={search} />);
    const sortedSearches = recentSearches.sort((a,b) => {return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()}).reverse();

    return (
      <>
        <h2>Recent Successful Searches</h2>
        Total: {allSearches.length}
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow key={"index"}>
                        <TableCell style={{fontSize: "1.1em"}}>Address</TableCell>
                        <TableCell style={{fontSize: "1.1em"}}>Date</TableCell>
                    </TableRow>
                </TableHead>
                {sortedSearches}
            </Table>
        </TableContainer>
      </>
    );
  }
}

export default AllSearches
