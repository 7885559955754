import React from 'react';

import './footer.css'
import MaisonLogo from '../../files/images/maisonLogo.svg'

export default function Header() {

  return (
    <footer>
        <div className="footer-wrapper">
            <div className="footer-main">
                <div className="footer-section-logo">
                    <img className="footer-logo" src={MaisonLogo} alt="maison logo" />
                </div>
                <div className="footer-section">
                    <h5>Madelon Group</h5>
                    <ul>
                        <li><a href="https://madelongroup.com/about">About</a></li>
                        <li><a href="https://maisonliving.io">Maison Living</a></li>
                        <li><a href="/">Admin Login</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h5>Resources</h5>
                    <ul>
                        <li><a href="/faqs">FAQs</a></li>
                        <li><a href="/terms-and-conditions">Terms And Conditions</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h5>Contact</h5>
                    <ul>
                        <li>Brooklyn, NY</li>
                        <li>Email: <a href="mailto:hello@madelongroup.com">maison@madelongroup.com</a></li>
                        <li>Phone: <a href="tel:3474645556">+1 (347) 464-5556</a></li>
                    </ul>
                </div>
            </div>
            <div className="copyright">
                <p>Copyright <a href="https://madelongroup.com">Madelon Group</a></p>
            </div>
        </div>
    </footer>
  );
}