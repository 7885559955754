import React from 'react'
import './App.css';
import Helmet from 'react-helmet'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'

import Home from './pages/home'
import Dashboard from './pages/dashboard'
import Page404 from './pages/page404'
import PrivacyPolicy from './pages/privacyPolicy'
import TermsAndConditions from './pages/tandc'
import FAQs from './pages/faqs'

import Header from './components/header/header'
import Footer from './components/footer/footer'
import Project from './components/project/project'
import ProjectReview from './components/project/projectReview'
import ProjectAdmin from './components/project/projectAdmin'
import ProjectPacket from './components/project/projectPacket'

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#AF1203',
      contrastText: '#fff'
    },
    secondary: {
      main: '#3535c9',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariant: false
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        boxShadow: 'none'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    }
  }
}));

function App() {
  return (
    <>
      <Helmet>
        <title>Maison NOI Optimizer</title>
        <meta name="description" content="The Maison NOI Optimizer is a platform that enables developers to increase the Net Operating Income of their property by 30% of more."></meta>
      </Helmet>
      <div className="app-wrapper">
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
              <Router>
                <Header />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/d779ee1f-bbc0-4023-a982-b7ec2813359e/dashboard" component={Dashboard} />
                  <Route exact path="/project/:id" component={Project} />
                  <Route exact path="/project/:id/packet" component={ProjectPacket} />
                  <Route exact path="/project/:id/review" component={ProjectReview} />
                  <Route exact path="/project/:id/admin" component={ProjectAdmin} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                  <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                  <Route exact path="/faqs" component={FAQs} />
                  <Route component={Page404} />
                </Switch>
                <Footer />
              </Router>
            </MuiThemeProvider>
        </StyledEngineProvider>
      </div>
    </>
  );
}

export default App;
