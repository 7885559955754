import React, { Component } from 'react'
import Moment from 'react-moment'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

class SingleRequest extends Component {
  render() {
    const {
      request: {
        id,
        email,
        address,
        createdAt,
        errortype,
        dateContacted
      }
    } = this.props;

    return (
        <TableBody>
          <TableRow key={id}>
            <TableCell>{address}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell><Moment date={createdAt} format="MMMM DD, YYYY" /></TableCell>
            <TableCell>{errortype}</TableCell>
            <TableCell>{dateContacted}</TableCell>
          </TableRow>
        </TableBody>
    );
  }
}

export default SingleRequest