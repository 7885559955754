import React, { Component } from 'react'

import Feature from './feature'

import './home.css'

class Features extends Component {

    render() {

        return (
            <div className="row-wide">
                <h1>What We Do</h1>
                <div className="features">
                    <Feature title="Design" body="It’s not about spending money on overpriced items, it’s an art & a science to help tenants feel at home and stay longer, at an efficient cost." backgroundColor="#2F51AA"/>
                    <Feature title="Market Analysis" body="Leverage our algorithm and years of experience to determine the rents, costs, and overall yield." backgroundColor="#2F51AA"/>
                    <Feature title="Financing" body="If you’re not sure how to finance a Maison project, we have options to help with that too. Let’s talk." backgroundColor="#2F51AA"/>
                    <Feature title="Operations" body="We work with the top property manager in NYC for day-to-day operations. Years in the business and experts in flexible living." backgroundColor="#2F51AA"/>
                    <Feature title="Partnerships" body="Everything needed to go from building to cash flow, we’ve got you covered." backgroundColor="#2F51AA"/>
                </div>            
            </div>
        )
    }
}

export default Features