import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Helmet from 'react-helmet'

import './project.css'

import LoadingSpinner from '../common/loadingSpinner'
import Cta from '../common/cta'
import Packet from './packet'
import Utility from "./functions/utility"
import Calculations from "./functions/calculations"
import AdminService from '../../services/admin.service'

import { Grid, Card, CardContent } from '@mui/material'

export default function ProjectPacket() {

    let { id } = useParams();

    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [queryInfo, setQueryInfo] = useState(null);
    // eslint-disable-next-line
    const [parcelInfo, setParcelInfo] = useState(null);
    // eslint-disable-next-line
    const [zoneInfo, setZoneInfo] = useState(null);
    // eslint-disable-next-line
    const [neighborhoodInfo, setNeighborhoodInfo] = useState(null);
    // eslint-disable-next-line
    const [realtyMoleInfo, setRealtyMoleInfo] = useState(null);
    // eslint-disable-next-line
    const [zipInfo, setZipInfo] = useState(null);
    // eslint-disable-next-line
    const [calculatedValues, setCalculatedValues] = useState(null);


    const [unitMix] = useState(75);


    useEffect(() => {
        (async function fetchAllData() {
            const { queryInfo, parcelInfo, zoneInfo, neighborhoodInfo, realtyMoleInfo } = await Utility.fetchInfo(id);
            setQueryInfo(queryInfo);
            setParcelInfo(parcelInfo);
            setZoneInfo(zoneInfo);
            setNeighborhoodInfo(neighborhoodInfo);
            setRealtyMoleInfo(realtyMoleInfo);

            const zipInfo = await AdminService.getZipcodeInfo(queryInfo.zipcode).then(response => {return response.data});
            setZipInfo(zipInfo);

            const calculatedValues = Calculations.numbers(queryInfo, parcelInfo, zoneInfo, neighborhoodInfo, realtyMoleInfo, unitMix);

            setCalculatedValues(calculatedValues);

            setLoading(false);
        })();
    }, [id, unitMix]);

    if(loading === true) {
        return (
            <>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <div className="loading-spinner"><LoadingSpinner /></div>
        </>
        )
    }

    else {

        return (
            <>
            <Helmet>
                <title>{queryInfo.address} | Maison NOI Optimizer</title>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <div className="packet-wrapper">
                <h1>{queryInfo.address}</h1>
                <Cta id={id}/>
                <Packet />
                <h2>Projected Numbers And Analysis</h2>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Card style={{backgroundColor: "#f2f2f2"}}>
                            <CardContent>
                                <p>Estimated Total Keys</p>
                                <h2>{calculatedValues.numberRooms}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Card style={{backgroundColor: "#f2f2f2"}}>
                            <CardContent>
                                <p>Projected Rent/Key</p>
                                <h2>$ {calculatedValues.rentAverage.toLocaleString()}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Card style={{backgroundColor: "#f2f2f2"}}>
                            <CardContent>
                                <p>Estimated Revenue</p>
                                <h2>$ {calculatedValues.revenue.toLocaleString()}</h2>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card style={{backgroundColor: "#AF1203", color: "#fff"}}>
                            <CardContent>
                                <h1>Projected NOI</h1>
                                <h1>$ {calculatedValues.noi.toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 })}</h1>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            </>
        );

    }

        
    }